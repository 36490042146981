if (module.hot) {
  require("../packs/respondent_survey/styles");
}
import "whatwg-fetch";
import React from "react";
import { hot } from "react-hot-loader";
import { BrowserRouter as Router, Route } from "react-router-dom";
import logo from "./img/logo.svg";

import { SurveyShowApp } from "./SurveyShowApp";

const Header = () => {
  return (
    <nav className="p-4 border-b-2 border-vanda-green">
      <div className="container max-w-xl mx-auto">
        <img src={logo} className="logo" alt="My logo" />
      </div>
    </nav>
  );
};


const RespondentSurveyApp = () => (
  <Router>
    <div className="h-screen flex flex-col">
      <Header />
      <Route path="/" exact render={() => <SurveyShowApp />} />
      <Route path="/s/:short_name" exact render={() => <SurveyShowApp />} />
    </div>
  </Router>
);
//
// proptypes help debugging down the line
//
// RespondentSurveyApp.propTypes = {
//   environment: PropTypes.object
// };
//
// exporting the app connected to redux,
// creating the new prop 'setFavorites' and mappoing it to redux dispatch
//
export default hot(module)(RespondentSurveyApp);
