import * as Types from "./actionTypes";

import { keyBy, flattenDeep } from "lodash";

export const surveyResponse = (state = null, action) => {
  switch (action.type) {
    case Types.SET_SURVEY_RESPONSE:
      return action.surveyResponse;
    default:
      return state;
  }
};

export const promptResponses = (state = [], action) => {
  switch (action.type) {
    case Types.UPDATE_PROMPT_RESPONSES:
      if (keyBy(state, "id")[action.promptResponse.id]) {
        return state.map(promptResponse =>
          promptResponse.id === action.promptResponse.id
            ? action.promptResponse
            : promptResponse
        );
      }
      return [...state, action.promptResponse];
    case Types.SET_PROMPT_RESPONSES:
      return action.promptResponses;
    default:
      return state;
  }
};

export const fulfilledConditions = (state = [], action) => {
  switch (action.type) {
    case Types.SET_SURVEY_CONDITIONS: {
      return action.surveyConditions.map(({ owner_id, subject_id }) => ({
        owner_id,
        subject_id,
        selected: false
      }));
    }
    case Types.UPDATE_SURVEY_CONDITIONS_FROM_RESPONSES: {
      const newState = state.map(fc => ({ ...fc, selected: false }));
      action.promptResponses.forEach(promptResponse => {
        if (promptResponse.prompt_option_ids) {
          promptResponse.prompt_option_ids.forEach(optionId => {
            newState.forEach(condition => {
              if (condition.owner_id === optionId) {
                condition.selected = true;
              }
            });
          });
        }
      });
      return newState;
    }
    default:
      return state;
  }
};

export const terminalConditions = (state = [], action) => {
  switch (action.type) {
    case Types.SET_TERMINAL_CONDITIONS:
      return action.terminalConditions;
    default:
      return state;
  }
};

export const selectedPromptOptions = (state = [], action) => {
  switch (action.type) {
    case Types.UPDATE_SURVEY_CONDITIONS_FROM_RESPONSES: {
      return flattenDeep(
        action.promptResponses.map(response => response.prompt_option_ids)
      ).filter(r => r !== null);
    }
    default:
      return state;
  }
};
