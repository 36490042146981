import "core-js/stable";
import "regenerator-runtime/runtime";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "../../RespondentSurveyApp/state/store"
import RespondentSurveyApp from "../../RespondentSurveyApp";

document.addEventListener("DOMContentLoaded", () => {
  const div = document.createElement("div");
  render(
  <Provider store={store}>
    <RespondentSurveyApp environment={window.Pluto} />
  </Provider>,
    document.body.insertBefore(div, document.body.firstElementChild)
  );
});
