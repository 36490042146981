import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@blueprintjs/core";
import { Loading } from "./Components/Loading";
import { getWildcardSurveyPath } from "../helpers/paths";
import { SurveyContainer as Survey } from "./Components/SurveyComponent";
import { useFetch } from "../helpers/hooks/useFetch";

import { useTranslation } from "react-i18next";
import { i18n } from "../translations/i18n";

const NoSurvey = () => {
  const { t } = useTranslation();
  return (<div className="bp4-non-ideal-state">
    <div className="bp4-non-ideal-state-visual">
      <Icon icon="disable" iconSize="60" intent="danger" />
    </div>
    <h4 className="bp4-heading text-red">{t('survey_not_found')}</h4>
  </div>)
};

export const Footer = (survey) => {
  return (
  <div className="p-4 border-t">
    <div className="container max-w-2xl mx-auto" dangerouslySetInnerHTML={ {__html: survey.footer_content } } />
    <div className="container max-w-2xl mx-auto text-gray-900">
      <p>&copy; Vanda Pharmaceuticals {new Date().getFullYear()} </p>
    </div>
  </div>
)};

Footer.propTypes = {
  survey: PropTypes.object
};

export const SurveyShowApp = () => {

  

  
  if (Pluto.wildcard_id != "") {
    var access_code = (new URLSearchParams(window.location.search)).get("access_code")
    const [loading, fulfilled, survey] = useFetch(getWildcardSurveyPath(Pluto.short_name, access_code));
    if(fulfilled){
      if(survey){
        i18n.changeLanguage(survey.language);
      } else {
        if(window.location.hostname.endsWith(".de")){
          i18n.changeLanguage('de');
        } else {
          i18n.changeLanguage('en');
        }
      }
    }

    return loading ? (
      <Loading />
    ) : fulfilled ? (
      <div>
        <div className="container max-w-l mx-auto flex-1 px-4 md:px-0">
          <Survey {...survey} />
        </div>
        <Footer {...survey}/>
      </div>
    ) : (
      <NoSurvey />
    );
  }else{
    return <NoSurvey />
  }
  
};
